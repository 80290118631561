import React from 'react';
import Header from './Header';
import Glossary from './Glossary';
import Footer from './Footer';

const HomeLayout = (props) => {
  return (
    <>
      <Header />
      {props.children}
      <Glossary />
      <Footer />
    </>
  );
};

export default HomeLayout;
