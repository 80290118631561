// Actual enums
export const funders = {
  CLEARBANC: 'clearbanc',
  LOOP: 'loop',
  SHOPIFY: 'shopify',
  OTHER_MCA: 'other mca',
};

// For dropdown
export const fundersOptions = [
  { name: 'Clearbanc', value: funders.CLEARBANC },
  { name: 'Loop', value: funders.LOOP },
  { name: 'Shopify', value: funders.SHOPIFY },
  { name: 'Other MCA', value: funders.OTHER_MCA },
];
