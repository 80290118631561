const dev = {
  bitlyToken: '5c500283a80efea321cc1a3b5888e2de9d139a25',
  bitlyGroupId: 'Bk9gfuLtuWT',
};

const config = {
  bitlyToken: process.env.REACT_APP_BITLY_TOKEN || dev.bitlyToken,
  bitlyGroupId: process.env.REACT_APP_BITLY_GROUP_ID || dev.bitlyGroupId,
};

export default config;
