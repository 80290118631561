import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './components/Home';
import { OfferContextProvider } from './context/offerContext';
import AddOffer from './components/offers/AddOffer';
import Offers from './components/offers/Offers';

const Routes = (props) => {
  return (
    <OfferContextProvider>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/offers" component={Offers} />
          <Route exact path="/add-offer" component={AddOffer} />
        </Switch>
      </Router>
    </OfferContextProvider>
  );
};

export default Routes;
