import xirr from 'xirr';
import { add } from 'date-fns';

// const xirr = require('xirr');
// const add = require('date-fns/add');

// Clearbanc APR Calculation
// repaymentRate: a rate at which borrower will repay loan amount to bank from everyday sales.
// e.g.: If everyday sale is 100$ and repayment rate is 20% than borrower will pay 20$ of loan payment every day
const calculateClearbancOffer = ({
  loanAmount,
  fee,
  repaymentRate,
  avgMonthlySales,
  monthlySalesGrowth,
  highestMonthlySalesInLTM,
}) => {
  const dailySalesGrowth = (Math.pow(1 + monthlySalesGrowth / 100, 1 / 30) - 1) * 100;
  const repaymentAmount = loanAmount + fee;

  const newData = [];

  for (let day = 0; day <= 364; day++) {
    const revenue =
      day < 30
        ? highestMonthlySalesInLTM / 30
        : day === 31
        ? avgMonthlySales / 30
        : newData[day - 1].revenue * (1 + dailySalesGrowth / 100);

    const loanPayment = (revenue * repaymentRate) / 100;
    const lastCumulativeLoanPayment = (newData[day - 1] && newData[day - 1].cumulativeLoanPayment) || 0;
    const cumulativeLoanPayment = lastCumulativeLoanPayment + loanPayment;
    const lastLoanPayment = newData[day - 1] && newData[day - 1].loanPayment;

    const stillPaying = cumulativeLoanPayment < repaymentAmount ? true : false;

    let loanPayments;
    if (day === 0) {
      loanPayments = -loanAmount;
    } else if (lastCumulativeLoanPayment < repaymentAmount) {
      loanPayments = lastLoanPayment;
    } else {
      const diff = lastCumulativeLoanPayment - repaymentAmount;
      loanPayments = diff > loanPayment ? 0 : loanPayment - diff;
    }
    newData.push({ revenue, loanPayment, cumulativeLoanPayment, stillPaying, loanPayments });
  }

  const xirrData = newData.map((d, i) => ({
    amount: d.loanPayments,
    when: add(new Date(), { days: i }),
  }));
  let apr;
  try {
    apr = xirr(xirrData);
  } catch (err) {
    console.error(err);
  }

  const monthlyPayment = -PMT(apr / 12, 12, 100000);
  const annualPayment = monthlyPayment * 12;
  const interest = annualPayment - 100000;
  const equivalentMonthlyInterest = interest / 12;

  const result = {
    daysToRepay: newData.filter((d) => d.loanPayments !== 0).length - 1,
    apr,
    repaymentAmount,
    loanAmount,
    equivalentMonthlyInterest,
  };
  return result;
};

var getMonthlyPayment = ({ loanAmount, interestRate, totalMonthlyPayments, fee }) => {
  const monthlyInterestRate = interestRate / 12;
  const foo = Math.pow(1 + monthlyInterestRate, totalMonthlyPayments);
  const monthlyPayment = ((loanAmount + fee) * foo * monthlyInterestRate) / (foo - 1);
  return monthlyPayment;
};

const calculateAPR = ({ loanAmount, interestRate, totalMonthlyPayments, fee }) => {
  const monthlyPayment = getMonthlyPayment({ loanAmount, interestRate, totalMonthlyPayments, fee });
  const monthlyInterestRate = interestRate / 12;

  let rate = monthlyInterestRate;
  let i = 1;
  let result = 0;
  let testdiff = rate;
  while (i <= 500) {
    const foo = Math.pow(1 + rate, totalMonthlyPayments);
    result = (rate * foo) / (foo - 1) - monthlyPayment / loanAmount;
    if (Math.abs(result) < 0.0000001) break;
    if (result < 0) rate += testdiff;
    else rate -= testdiff;
    testdiff = testdiff / 2;
    i++;
  }
  rate = rate * 12;
  return rate.toFixed(6);
};

// timeToRepay: in months
// quarterlyFee: in percent
// monthlyInterestRate: in percent
// https://docs.google.com/spreadsheets/d/1pGpIi8Ylhwr7LU7JcF_txxirahPFv84zQ10esRbQT0w/edit#gid=0
const calculateLoopOffer = ({ loanAmount, monthlyInterestRate }) => {
  const DRAW_FEE = 1; // Percent
  const MINIMUM_REPAYMENT = 6; // Percent
  const DRAW_AMOUNT = 30;

  const data = [];

  for (let i = 0; i <= 12; i++) {
    const begMonthBal = i === 0 ? 0 : data[i - 1].endMonthBal;
    const mandatoryPayment = i === 0 ? 0 : (begMonthBal * MINIMUM_REPAYMENT) / 100;
    const draw =
      i === 0 ? (loanAmount * DRAW_AMOUNT) / 100 : (loanAmount * DRAW_AMOUNT) / 100 - (begMonthBal - mandatoryPayment);

    const drawFee = (draw * DRAW_FEE) / 100;
    const balanceBeforeDrawFee = begMonthBal - mandatoryPayment + draw;
    const interimBalance = drawFee + balanceBeforeDrawFee;
    const intExp = (interimBalance * monthlyInterestRate) / 100;
    const endMonthBal = interimBalance + intExp;
    const cashFlow = i == 12 ? -endMonthBal : draw - mandatoryPayment;
    data.push({
      begMonthBal,
      mandatoryPayment,
      draw,
      drawFee,
      balanceBeforeDrawFee,
      interimBalance,
      intExp,
      endMonthBal,
      cashFlow,
    });
  }

  const xirrData = data.map((d, i) => ({
    amount: d.cashFlow,
    when: add(new Date('01-01,2020'), { months: i }),
  }));

  let apr;
  try {
    apr = xirr(xirrData);
  } catch (err) {
    console.error(err);
  }

  const monthlyPayment = -PMT(apr / 12, 12, 100000);
  const annualPayment = monthlyPayment * 12;
  const interest = annualPayment - 100000;
  const equivalentMonthlyInterest = interest / 12;

  const result = {
    apr,
    repaymentAmount: loanAmount,
    loanAmount,
    daysToRepay: 'unlimited',
    equivalentMonthlyInterest,
  };
  return result;
};

const calculateOtherMCAOffer = ({ loanAmount, numberOfPayments, repaymentRateInAmount }) => {
  const fee = repaymentRateInAmount * numberOfPayments - loanAmount;
  const repaymentAmount = loanAmount + fee;

  let counter = 1;
  const data = [];
  for (let i = 0; i < 365; i++) {
    let loanPayment;
    if (counter <= 5) loanPayment = repaymentRateInAmount;
    if (counter > 5 && counter <= 7) loanPayment = 0;
    const lastCumulativeLoanPayment = (data[i - 1] && data[i - 1].cumulativeLoanPayment) || 0;
    const lastLoanPayment = (data[i - 1] && data[i - 1].loanPayment) || 0;
    const cumulativeLoanPayment = lastCumulativeLoanPayment + loanPayment;

    let loanPayments;
    let paying = true;
    if (i === 0) {
      loanPayments = -loanAmount;
    } else if (lastCumulativeLoanPayment < repaymentAmount) {
      loanPayments = lastLoanPayment;
    } else {
      let diff = lastCumulativeLoanPayment - repaymentAmount;
      diff = Number(Number(diff).toFixed(4));
      if (diff > lastLoanPayment) {
        loanPayments = 0;
        paying = false;
      } else {
        loanPayments = lastLoanPayment - diff;
      }
    }

    data.push({ loanPayment, cumulativeLoanPayment, loanPayments, paying });
    counter = counter >= 7 ? 1 : counter + 1;
  }

  const xirrData = data.map((d, i) => ({
    amount: d.loanPayments,
    when: add(new Date(), { days: i }),
  }));
  let apr;
  try {
    apr = xirr(xirrData);
  } catch (err) {
    console.error(err);
  }

  const monthlyPayment = -PMT(apr / 12, 12, 100000);
  const annualPayment = monthlyPayment * 12;
  const interest = annualPayment - 100000;
  const equivalentMonthlyInterest = interest / 12;

  return {
    repaymentAmount,
    apr,
    daysToRepay: data.filter((d) => d.paying).length - 2,
    loanAmount,
    equivalentMonthlyInterest,
  };
};

const PMT = (ir, np, pv, fv = 0, type = 0) => {
  let pmt, pvif;
  if (ir === 0) return -(pv + fv) / np;
  pvif = Math.pow(1 + ir, np);
  pmt = (-ir * pv * (pvif + fv)) / (pvif - 1);
  if (type === 1) pmt /= 1 + ir;
  return pmt;
};

export { calculateClearbancOffer, calculateLoopOffer, calculateOtherMCAOffer };

//console.log(calculateMCAOffer({ loanAmount: 35500, numberOfPayments: 248, repaymentRate: 174.63 }));

// console.log(
//   calculateClearbancOffer({
//     loanAmount: 10000,
//     fee: 1000,
//     repaymentRate: 15,
//     avgMonthlySales: 8000,
//     highestMonthlySalesInLTM: 8000,
//     monthlySalesGrowth: 0,
//   })
// );

// console.log(
//   calculateClearbancOffer({
//     loanAmount: 20000,
//     fee: 2000,
//     repaymentRate: 20,
//     avgMonthlySales: 10000,
//     monthlySalesGrowth: 15,
//   })
// );

//console.log(calculateLoopOffer({ loanAmount: 100000, monthlyInterestRate: 1, quaterlyFee: 0, drawAmount: 30 }));
