import React from 'react';
import styles from './Header.module.scss';

import { FaCheckCircle } from 'react-icons/fa';

const Home = () => {
  return (
    <div className="bg-light-blue">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className={styles.heading}>Loan Comparison Calculator</h1>
            <p className={styles.subHeading}>
              Calculate the <span className={styles.textH}>true cost</span> of any funding offers that you are
              considering for your <span className={styles.textH}>eCommerce business</span>.
            </p>
            <div className={styles.feature}>
              <div className={styles.item}>
                <FaCheckCircle className="text-success mr-2" /> No account required
              </div>
              <div className={styles.item}>
                <FaCheckCircle className="text-success mr-2" /> No personal details required
              </div>
              <div className={styles.item}>
                <FaCheckCircle className="text-success mr-2" /> Compare offers in seconds
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
