import React from 'react';
import styles from './GetStarted.module.scss';

import { Link } from 'react-router-dom';

const GetStarted = () => {
  return (
    <div className="bg-primary text-white py-5">
      <div className={styles.container}>
        <h5 className="text-center mb-5">Get Started</h5>

        <ul className={styles.list}>
          <li className={styles.listItem}>
            <span className={styles.counter}>1</span>Add an offer from the funding provider you received an offer from
          </li>
          <li className={styles.listItem}>
            <span className={styles.counter}>2</span>Enter a few details about the offer you’ve received
          </li>
          <li className={styles.listItem}>
            <span className={styles.counter}>3</span>Add any other offers to make an 🍎 to 🍎 comparison
          </li>
        </ul>

        <Link className="btn btn-success btn-block mt-5" to="/add-offer">
          Add a Funding Offer
        </Link>
      </div>
    </div>
  );
};

export default GetStarted;
