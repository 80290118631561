import React from 'react';
import HomeLayout from './layout/HomeLayout';
import GetStarted from './layout/GetStarted';

const Home = () => {
  return (
    <HomeLayout>
      <GetStarted />
    </HomeLayout>
  );
};

export default Home;
