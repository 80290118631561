import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import { numberToCurrency } from '../../utility/currency';
import styles from './Offers.module.scss';
import HomeLayout from '../layout/HomeLayout';
import { OfferContext } from '../../context/offerContext';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import config from '../../config';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Text, LabelList, Cell } from 'recharts';
import { funders } from '../../constant';

const Offers = () => {
  const history = useHistory();
  const { deleteOffer, offers, addOffers } = useContext(OfferContext);
  const [gettingLink, setGettingLink] = useState();
  const [bitlyLink, setBitlyLink] = useState();
  const [copyBtnMsg, setCopyBtnMsg] = useState('Copy');

  useEffect(() => {
    const { data } = queryString.parse(window.location.search);
    const offersFromParams = data && JSON.parse(data);

    if ((!offers || !offers.length) && (!offersFromParams || !offersFromParams.length)) {
      history.push('/');
    } else {
      addOffers(offersFromParams || []);
      history.push('/offers');
    }
  }, []);

  const handleShareClick = async () => {
    setGettingLink(true);
    const queryData = queryString.stringify({ data: JSON.stringify(offers) });
    const url = `${window.location.origin}/offers?${queryData}`;

    const postUrl = 'https://api-ssl.bitly.com/v4/shorten';
    const postData = {
      group_guid: config.bitlyGroupId,
      domain: 'bit.ly',
      long_url: url,
    };

    const headers = {
      headers: {
        Authorization: `Bearer ${config.bitlyToken}`,
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(postUrl, postData, headers);
    const link = res && res.data && res.data.link;
    setGettingLink(false);
    setBitlyLink(link);
  };

  const getOffers = () => {
    return offers.map((o, i) => {
      const offer = o.offer;
      if (!offer) return null;

      const funder = o.offerInput && o.offerInput.funder;
      const loanAmount = numberToCurrency(offer.loanAmount);
      const repaymentAmount = numberToCurrency(Number(offer.repaymentAmount).toFixed(2));
      const apr = Number(offer.apr * 100).toFixed(2);

      return (
        <div key={i} className="mb-5">
          <h6 className="mb-2">
            Offer #{i + 1}{' '}
            <Link
              className="btn btn-success py-0 mx-2"
              to={{ pathname: '/add-offer', state: { offer: o, offerIndex: i } }}
            >
              Edit
            </Link>
            <button type="button" className="btn btn-danger py-0 mx-2" onClick={() => deleteOffer(i)}>
              Delete
            </button>
          </h6>
          <div className="d-flex justify-content-between">
            <div>
              <div>Funder</div>
              <div className={styles.itemValue + ' text-capitalize'}>{funder}</div>
            </div>
            <div>
              <div>Funding Amount</div>
              <div className={styles.itemValue}>{loanAmount}</div>
            </div>
            <div>
              <div>Repayment Amount</div>
              <div className={styles.itemValue}>{repaymentAmount}</div>
            </div>
            <div>
              <div>Days to Pay Back</div>
              <div className={styles.itemValue}>{offer.daysToRepay}</div>
            </div>
            <div>
              <div>Estimated APR</div>
              <div className={styles.itemValue}>
                <b>
                  <u>{apr}%</u>
                </b>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const handleOnCopy = () => {
    setCopyBtnMsg('Copied!');
    setTimeout(() => {
      setCopyBtnMsg('Copy');
    }, 2000);
  };

  const getChart = () => {
    if (!offers || offers.length < 2) return null;

    const data = offers.map((offer, i) => {
      const name = offer && offer.offerInput && offer.offerInput.funder;
      const capitalizeName = name.charAt(0).toUpperCase() + name.slice(1);

      let color = '#8884d8';

      if (name === funders.CLEARBANC) color = '#D32F2F';
      if (name === funders.SHOPIFY) color = '#FF5722';
      if (name === funders.OTHER_MCA) color = '#303F9F';
      if (name === funders.LOOP) color = '#4CAF50';

      return {
        name: `${capitalizeName} (Offer #${i + 1})`,
        interest: Number(Number(offer.offer.equivalentMonthlyInterest).toFixed(2)),
        color,
      };
    });

    return (
      <div className={styles.chartWrapper + ' mt-5 bg-light p-4'}>
        <h5 className="text-center mb-5">Equivalent Monthly Interest</h5>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="name" />
            <YAxis
              tickFormatter={(tick) => numberToCurrency(tick)}
              label={
                <Text x={0} y={0} dx={12} dy={292} offset={0} angle={-90}>
                  Equivalent Monthly Interest
                </Text>
              }
            />

            <Bar dataKey="interest" fill="#8884d8">
              <LabelList
                dataKey="interest"
                fill="black"
                position="top"
                content={({ value }) => numberToCurrency(value)}
              />
              {data.map((entry, index) => {
                return <Cell fill={entry.color} />;
              })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  return (
    <HomeLayout>
      <div className="py-5 text-primary">
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <h3 className="mb-5">
                Offers
                {!bitlyLink ? (
                  <button
                    type="button"
                    className="btn btn-success py-0 float-right"
                    onClick={handleShareClick}
                    disabled={gettingLink}
                  >
                    Share
                  </button>
                ) : (
                  <div className="float-right d-flex align-items-center">
                    <code className="fs1 text-info font-weight-light">{bitlyLink}</code>
                    <CopyToClipboard text={bitlyLink} onCopy={handleOnCopy}>
                      <button className="btn btn-success py-0 ml-2">
                        <small>{copyBtnMsg}</small>
                      </button>
                    </CopyToClipboard>
                  </div>
                )}
              </h3>

              {getOffers()}

              <div className="d-flex justify-content-center">
                <Link className="btn btn-success px-5 mt-5" to="/add-offer">
                  + Add another offer
                </Link>
              </div>

              {getChart()}
            </div>
          </div>
        </div>
      </div>
    </HomeLayout>
  );
};

export default Offers;
