import React, { createContext, useState } from 'react';

const OfferContext = createContext({
  offers: [],
});

const OfferContextProvider = (props) => {
  const [offers, setOffers] = useState([]);

  const addOffers = (newOffers) => {
    setOffers([...offers, ...newOffers]);
  };

  const updateOffer = (newOffer, index) => {
    offers[index] = newOffer;
    setOffers([...offers]);
  };

  const deleteOffer = (index) => {
    offers.splice(index, 1);
    setOffers([...offers]);
  };

  return (
    <OfferContext.Provider
      value={{
        offers,
        addOffers,
        updateOffer,
        deleteOffer,
      }}
    >
      {props.children}
    </OfferContext.Provider>
  );
};

export { OfferContext, OfferContextProvider };
