import React, { useContext } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import styles from './AddOffer.module.scss';
import { Select, TextField } from '../form/FormFields';
import { fundersOptions, funders } from '../../constant';
import { currencyToNumber, numberToCurrency } from '../../utility/currency';
import { OfferContext } from '../../context/offerContext';
import { calculateClearbancOffer, calculateLoopOffer, calculateOtherMCAOffer } from '../../utility/formulas';
import HomeLayout from '../layout/HomeLayout';

const AddOfferForm = (props) => {
  const { history } = props;
  const { addOffers, updateOffer } = useContext(OfferContext);
  const { offer, offerIndex } = (history && history.location.state) || {};
  const { offerInput } = offer || {};
  const form = useForm();
  const { register, handleSubmit, watch } = form;
  const defaultFunder = offerInput && offerInput.funder;
  const selectedFunder = watch('funder', defaultFunder);

  const isLoop = selectedFunder === funders.LOOP;
  const isClearbanc = selectedFunder === funders.CLEARBANC;
  const isShopify = selectedFunder === funders.SHOPIFY;
  const isOtherMCA = selectedFunder === funders.OTHER_MCA;

  const onSubmit = (data) => {
    const offerInput = {
      loanAmount: currencyToNumber(data.fundingAmount),
      fee: currencyToNumber(data.feeAmount),
      repaymentRate: Number(data.repaymentRate),
      repaymentRateInAmount: currencyToNumber(data.repaymentRateInAmount),
      avgMonthlySales: currencyToNumber(data.avgMonthlySales),
      monthlySalesGrowth: Number(data.monthlySalesGrowth),
      funder: data.funder,
      monthlyInterestRate: data.monthlyInterestRate,
      highestMonthlySalesInLTM: currencyToNumber(data.highestMonthlySalesInLTM),
      numberOfPayments: Number(data.numberOfPayments),
    };

    let offerNew;
    if (isLoop) offerNew = calculateLoopOffer(offerInput);
    if (isClearbanc || isShopify) offerNew = calculateClearbancOffer(offerInput);
    if (isOtherMCA) offerNew = calculateOtherMCAOffer(offerInput);

    if (offerIndex > -1) {
      updateOffer({ offerInput, offer: offerNew }, offerIndex);
    } else {
      addOffers([{ offerInput, offer: offerNew }]);
    }

    history.push('offers');
  };

  const getClearbancFields = () => {
    return (
      <>
        {' '}
        <TextField
          rootClass="col-lg-6 mb-2"
          name="fundingAmount"
          label="Funding amount"
          placeholder="$100,000"
          isCurrency
          ref={register({ required: true })}
          defaultValue={offerInput && offerInput.loanAmount && numberToCurrency(offerInput.loanAmount)}
        />
        <TextField
          rootClass="col-lg-6 mb-2"
          name="feeAmount"
          label="Fee amount"
          placeholder="$6000"
          isCurrency
          ref={register({ required: true })}
          defaultValue={offerInput && offerInput.fee}
        />
        <TextField
          rootClass="col-lg-6 mb-2"
          name="avgMonthlySales"
          label="Average monthly sales"
          placeholder="$62,000"
          isCurrency
          ref={register({ required: true })}
          defaultValue={offerInput && offerInput.avgMonthlySales && numberToCurrency(offerInput.avgMonthlySales)}
        />
        <TextField
          rootClass="col-lg-6 mb-2"
          name="highestMonthlySalesInLTM"
          label="Highest Monthly Sales in last twelve months "
          isCurrency
          placeholder="$70,000"
          ref={register({ required: true })}
          defaultValue={offerInput && offerInput.highestMonthlySalesInLTM}
        />
        <TextField
          rootClass="col-lg-6 mb-2"
          name="monthlySalesGrowth"
          label="Monthly sales growth(%)"
          placeholder="15%"
          ref={register({ required: true })}
          defaultValue={offerInput && offerInput.monthlySalesGrowth}
        />
        <TextField
          rootClass="col-lg-6 mb-2"
          name="repaymentRate"
          label="Repayment Rate(% of Daily Sales)"
          placeholder="20%"
          ref={register({ required: true })}
          defaultValue={offerInput && offerInput.repaymentRate}
        />
      </>
    );
  };

  const getLoopFields = () => {
    return (
      <>
        <TextField
          rootClass="col-12 mb-2"
          name="fundingAmount"
          label="Funding amount"
          placeholder="$100,000"
          isCurrency
          ref={register({ required: true })}
          defaultValue={offerInput && offerInput.loanAmount && numberToCurrency(offerInput.loanAmount)}
        />
        <TextField
          rootClass="col-12 mb-2"
          name="monthlyInterestRate"
          label="Monthly interest rate(%)"
          placeholder="1%"
          ref={register({ required: true })}
          defaultValue={offerInput && offerInput.monthlyInterestRate}
        />
      </>
    );
  };
  const getOtherMCAFields = () => {
    return (
      <>
        <TextField
          rootClass="col-12 mb-2"
          name="fundingAmount"
          label="Funding amount"
          placeholder="$35,500"
          isCurrency
          ref={register({ required: true })}
          defaultValue={offerInput && offerInput.loanAmount && numberToCurrency(offerInput.loanAmount)}
        />
        <TextField
          rootClass="col-lg-6 mb-2"
          name="numberOfPayments"
          label="Number of payments"
          placeholder="248"
          ref={register({ required: true })}
          defaultValue={offerInput && offerInput.numberOfPayments}
        />
        <TextField
          rootClass="col-lg-6 mb-2"
          name="repaymentRateInAmount"
          label="Repayment Rate"
          placeholder="$174"
          isCurrency
          ref={register({ required: true })}
          defaultValue={
            offerInput && offerInput.repaymentRateInAmount && numberToCurrency(offerInput.repaymentRateInAmount)
          }
        />
      </>
    );
  };

  return (
    <HomeLayout>
      <div className="bg-primary text-white py-4">
        <div className={styles.container}>
          <h3 className="text-center mb-5">{offer ? 'Update offer' : 'Add Funding Offer'}</h3>

          <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <Select
                  rootClass="col-12 mb-2"
                  name="funder"
                  label="Who is the funding offer from?"
                  ref={register({
                    required: true,
                    validate: (value) => {
                      console.log(value, value !== 'select');
                      return value !== 'select';
                    },
                  })}
                  options={fundersOptions}
                  defaultValue={defaultFunder}
                />

                {isClearbanc || isShopify ? getClearbancFields() : null}
                {isLoop ? getLoopFields() : null}
                {isOtherMCA ? getOtherMCAFields() : null}
              </div>
              <button type="submit" className="btn btn-success btn-block mt-4">
                Submit
              </button>
            </form>
          </FormProvider>
        </div>
      </div>
    </HomeLayout>
  );
};

export default AddOfferForm;
