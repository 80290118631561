import React, { forwardRef } from 'react';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';

import { numberToCurrency, currencyToNumber } from '../../utility/currency';

const TextFieldComponent = (props, ref) => {
  const { label, name, rootClass, isCurrency, ...other } = props;
  const { errors, setValue } = useFormContext();
  const hasError = _.has(errors, name);
  const error = _.get(errors, name);

  const handleOnChange = (e) => {
    if (isCurrency) {
      const intValue = currencyToNumber(e.target.value);
      const value = numberToCurrency(isNaN(intValue) ? '0' : intValue);
      setValue(name, value);
    }
  };

  return (
    <div className={`${rootClass} form-group`}>
      <label className="input-label small font-weight-bold" htmlFor={name}>
        {label}
      </label>
      <input
        type="text"
        className={`form-control ${hasError ? 'is-invalid' : ''}`}
        name={name}
        onChange={handleOnChange}
        id={name}
        {...other}
        ref={ref}
      />
      {hasError ? <span className="small text-danger font-weight-bold">{error && error.message}</span> : null}
    </div>
  );
};

const SelectFieldComponent = (props, ref) => {
  const { label, name, options = [], rootClass, defaultValue, ...other } = props;
  const { errors } = useFormContext();
  const hasError = errors && errors[name];

  return (
    <div className={`${rootClass} form-group`}>
      <label className="input-label small font-weight-bold" htmlFor={name}>
        {label}
      </label>
      <select
        name={name}
        ref={ref}
        className={`form-control ${hasError ? 'is-invalid' : ''}`}
        id={name}
        defaultValue={defaultValue || 'select'}
        {...other}
      >
        <option value="select" disabled>
          Select
        </option>
        {options.map((o, i) => (
          <option key={i} value={o.value}>
            {o.name}
          </option>
        ))}
      </select>
    </div>
  );
};

const TextField = forwardRef(TextFieldComponent);
const Select = forwardRef(SelectFieldComponent);

export { TextField, Select };
