import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { IoMdClose } from 'react-icons/io';

const Glossary = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="bg-light-blue py-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3 className="mb-4">Glossary</h3>
            <h6>Funding Amount</h6>
            <p>The total amount of funding that you will receive from the Funder.</p>
            <h6>Annual Percentage Rate (APR)</h6>
            <p>
              The Annual Percentage Rate (APR) makes it easy to compare loan offers. APR represents the annualized rate
              that you pay for each dollar that you borrow - for example, if you were to borrow $100 for 365 days at a
              20% APR (without paying back a single dollar during the year), you would pay $20 in costs for the $100 you
              borrowed.
            </p>
            <h6>Days to Repay</h6>
            <p>
              Different lenders require you to repay the loan over different periods of time. The shorter the amount of
              days that you have to repay a loan, the higher an APR will usually be since you would have to borrow more
              frequently and therefore pay more in charges and interest.
            </p>
            <h6>Equivalent Monthly Interest</h6>
            <p>
              This chart shows how much you would need to pay each lender in interest every month if the repayment
              period was exactly 365 days and uses the loan amount of your highest funding option. By using the same
              loan amount, repayment time and payback period, the interest that you would pay on each of these loans is
              calculated on a true Apples-to-Apples basis. Therefore, the equivalent monthly interest number
              demonstrates in actual dollar terms how much one loan would actually cost your business in a given year
              compared to your other loan options. For shorter term loans/advances, the annual cost is almost always
              higher because in order to maintain your cash flow, you need to borrow from them multiple times per year
              which increases your average cost.
            </p>

            <button type="button" className="btn btn-link pl-0 fwb" onClick={() => setShowModal(true)}>
              <b>
                <u>What Assumptions Are Made In These Calculations</u>
              </b>
            </button>
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        shouldFocusAfterRender={true}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        parentSelector={() => document.body}
        ariaHideApp={false}
        style={localStyles}
      >
        <button
          className="border-0 bg-white position-absolute"
          onClick={() => setShowModal(false)}
          style={{ right: 18, top: 18 }}
        >
          <IoMdClose size={22} />
        </button>
        <div>
          <h5>Clearbanc / Shopify APR</h5>
          <p>
            Calculation assumes highest monthly sales for the first month of repayment, followed by average monthly
            sales increasing by the monthly growth rate for subsequent months. XIRR Calculation is used on the daily
            repayments to calculate an APR for the advance.
          </p>
          <h5>Loop APR</h5>
          <p>
            Calculation assumes 30% of the facility is drawn in the first month, there is one subsequent drawdown each
            month and average utilization is 30% over the full year.
          </p>
        </div>
      </ReactModal>
    </div>
  );
};

export default Glossary;

const localStyles = {
  overlay: {
    zIndex: 99,
    background: 'rgba(0, 0, 0, 0.75)',
  },
  content: {
    maxWidth: 732,
    margin: '0 auto',
    padding: 42,
    bottom: 'auto',
  },
};
