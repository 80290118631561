import React from 'react';

const Footer = () => {
  return (
    <div className="bg-light-blue py-5 text-center">
      <div>
        <b>
          Made with love by{' '}
          <a className="text-underline" href="https://getloop.ca">
            Loop
          </a>{' '}
          💜
        </b>
      </div>
      <a href="mailto:hello@whatthecost.app">hello@whatthecost.app</a>
    </div>
  );
};

export default Footer;
